@charset 'utf-8';

/* purgecss start ignore */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* purgecss end ignore */

@import "./common/index";
@import "./components/index";
@import "./utils/index";
@import "./page/index";

/* styles.css */
@layer components {
    [class*="bg-brand-"] {
      @apply transition-bg duration-300 ease-in-out;
    }
  
    [class*="text-brand-"] {
      @apply transition-text duration-300 ease-in-out;
    }

    [class*="fill-brand-"] {
      @apply transition-fill duration-300 ease-in-out;
    }
  }
  