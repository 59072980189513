$height: 44;

.tgg-phoneNumber {
  width: 100%;

  input {
    height: $height + px !important;
    border-radius: 6px !important;
    background: none !important;
    transition: border-color 0.2s;
    border: 1px solid $neutrals6 !important;
    color: $primary3;
    width: 100% !important;
    &:hover {
      border-color: $neutrals5 !important;
    }
    &:focus {
      border-color: $neutrals4 !important;
    }
    &::placeholder {
      color: $neutrals4 !important;
    }
    @include dark-body {
      color: $neutrals8 !important;
      border-color: $neutrals3 !important;
      &:hover {
        border-color: $neutrals4 !important;
      }
      &:focus {
        border-color: $neutrals5 !important;
      }
    }
  }
  .flag-dropdown {
    background: none !important;
    border-color: $neutrals6 !important;
    border-right: 1px solid;
    border-left-width: 0px !important;
    border-top-width: 0px !important;
    border-bottom-width: 0px !important;
    @include dark-body {
      border-color: $neutrals3 !important;
    }
  }
  svg.margin {
    height: 20px;
  }
  &-error {
    input {
      border-color: $accent2 !important;
      &:focus {
        border-color: $accent2 !important;
      }
    }
  }
}
