@import "../common/colors";

.resizableNoteTable {
  width: 100%;
}

.resizableNoteTable table {
  border-collapse: collapse;
  width: 100%;
}

.resizableNoteTable th,
.resizableNoteTable td {
  padding: 2px;
  height: 20px;
  min-height: 20px;
  border: 1px solid #ccc;
  position: relative;
}

.resizableNoteTable .resizer {
  width: 8px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  cursor: col-resize;
  background: #eee;
}

.resizableTable {
  // width: 100%;
  max-width: 630px;
}

.resizableTable table {
  border-collapse: collapse;
  width: 100%;
}

.resizableTable th,
.resizableTable td {
  padding: 2px;
  height: 20px;
  min-height: 20px;
  border: 1px solid #ccc;
  position: relative;
}

.resizableTable .resizer {
  width: 8px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  cursor: col-resize;
  background: #eee;
}

.resizableTable-notes {
  // width: 100%;
  max-width: 630px;
}

.resizableTable-notes table {
  border-collapse: collapse;
  width: 100%;
}

.resizableTable-notes th,
.resizableTable-notes td {
  padding: 2px;
  height: 20px;
  min-height: 20px;
  border: 1px solid #ccc;
  position: relative;
}

.resizableTable-notes .resizer {
  width: 8px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: col-resize;
  background: #eee;
}

.resizableTable-notes {
  // width: 100%;
  max-width: 630px;
}

.resizableTable-notes table {
  border-collapse: collapse;
  width: 100%;
}

.resizableTable-notes th,
.resizableTable-notes td {
  padding: 2px;
  height: 20px;
  min-height: 20px;
  border: 1px solid #ccc;
  position: relative;
}

.resizableTable-notes .resizer {
  width: 8px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: col-resize;
  background: #eee;
  z-index: 100;
}
