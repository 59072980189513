@mixin borderColor6 {
  border-color: $neutrals6;
  @include dark-body {
    border-color: $neutrals3;
  }
}

.border {
  &-common {
    border: 1px solid $neutrals6;
    @include borderColor6;
  }
  &-common-50 {
    border: 1px solid rgba($neutrals6, 0.5);
  }
  &-common-r {
    border-right: 1px solid $neutrals6;
    @include borderColor6;
  }
  &-common-t {
    border-top: 1px solid $neutrals6;
    @include borderColor6;
  }
  &-common-t-50 {
    border-top: 1px solid rgba($neutrals6, 0.5);
  }
  &-common-b {
    border-bottom: 1px solid;
    @include borderColor6;

    &-dark {
      border-bottom: 1px solid $neutrals5;
      @include dark-body {
        border-bottom: 1px solid $neutrals3;
      }
    }
  }
  &-common-l {
    border-left: 1px solid $neutrals6;
    @include borderColor6;
  }
  &-accent {
    border: 1px solid $accent1;
  }
  &-accent-t {
    border-top: 1px solid $accent1;
  }
  &-accent-b {
    border-bottom: 1px solid $accent1;
  }
  &-accent-l {
    border-left: 1px solid $accent1;
  }
  &-accent-r {
    border-right: 1px solid $accent1;
  }
  &-error {
    border: 1px solid $accent2;
  }
  &-error-t {
    border-top: 1px solid $accent2;
  }
  &-error-b {
    border-bottom: 1px solid $accent2;
  }
  &-error-l {
    border-left: 1px solid $accent2;
  }
  &-error-r {
    border-right: 1px solid $accent2;
  }
  &-dark {
    border: 1px solid $neutrals2;
  }
  &-dark-t {
    border-top: 1px solid $neutrals2;
  }
  &-dark-b {
    border-bottom: 1px solid $neutrals2;
  }
  &-dark-l {
    border-left: 1px solid $neutrals2;
  }
  &-dark-r {
    border-right: 1px solid $neutrals2;
  }
  &-primary {
    border: 1px solid $primary2;
  }
  &-primary-t {
    border-top: 1px solid $primary2;
  }
  &-primary-b {
    border-bottom: 1px solid $primary2;
  }
  &-green {
    border: 1px solid $accent3;
  }
  &-green-l {
    border-left: 1px solid $accent3;
  }
  &-primary2-l {
    border-left: 1px solid $primary2;
  }
  &-common-neutrals5 {
    border: 1px solid $neutrals5;
  }
  &-common-b-neutrals5 {
    border-bottom: 1px solid $neutrals5;
  }
  &-common-r-neutrals5 {
    border-right: 1px solid $neutrals5;
  }
  &-common-l-neutrals5 {
    border-left: 1px solid $neutrals5;
  }
  &-common-t-neutrals5 {
    border-top: 1px solid $neutrals5;
  }
}

.rounded1 {
  border-radius: 6px;
}

.rounded2 {
  border-radius: 10px;
}

.rounded-15 {
  border-radius: 15px;
}
.rounded-5 {
  border-radius: 5px;
}
.rounded-8 {
  border-radius: 8px;
}

.rounded-common {
  &-tl {
    border-top-left-radius: 6px;
  }
  &-tr {
    border-top-right-radius: 6px;
  }
  &-bl {
    border-bottom-left-radius: 6px;
  }
  &-br {
    border-bottom-right-radius: 6px;
  }
}

.rounded {
  &-b {
    &-3 {
      border-radius: 0 0 3px 3px;
    }
  }
}
