[class*=" neu-"]::before,
[class^="neu-"]::before {
  margin: 0;
}

.rounded-number {
  &-6 {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
  &-13 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  &-16 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }
}

.invitationFlag {
  text-transform: capitalize;
  .pending {
    color: $neutrals9;
    background-color: $primary2;
    padding: 0 6px;
    border-radius: 1rem;
  }
  .accepted {
    color: $neutrals9;
    background-color: $greenLight;
    padding: 0 6px;
    border-radius: 1rem;
  }
  .rejected,
  .cancelled {
    color: $neutrals9;
    background-color: $redLight;
    padding: 0 6px;
    border-radius: 1rem;
  }
}

.badge {
  &-common {
    @apply typo-13-500 capitalize bg-neutrals2 dark:bg-neutrals3 rounded1 px-2 text-neutrals9 dark:text-neutrals6;
  }
  &-primary1 {
    @apply typo-13-500 px-2 rounded1 bg-blue text-neutrals9 uppercase;
  }
  &-primary2 {
    @apply typo-13-500 px-2 rounded1 bg-primary2 text-neutrals9 uppercase;
  }
  &-accent {
    @apply typo-13-500 px-2 rounded1 bg-accent1 text-neutrals9 uppercase;
  }
  &-success {
    @apply typo-13-500 px-2 rounded1 bg-green text-neutrals9 uppercase;
  }
  &-error {
    @apply typo-13-500 px-2 rounded1 bg-red bg-opacity-10 text-red uppercase;
  }
  &-accent-n {
    @apply bg-accent1 bg-opacity-80 text-neutrals9 uppercase typo-13-600 leading-none flex items-center justify-center py-1 px-2 rounded-2xl;
  }
  &-success-n {
    @apply bg-green text-neutrals9 uppercase typo-13-600 leading-none flex items-center justify-center py-1 px-2 rounded-2xl;
  }
  &-primary-n {
    @apply bg-primary2 bg-opacity-80 text-neutrals9 uppercase typo-13-600 leading-none flex items-center justify-center py-1 px-2 rounded-2xl;
  }
  &-redLight {
    @apply typo-13-500 px-2 rounded1 bg-redLight text-neutrals9 capitalize typo-13-600 leading-none flex items-center justify-center py-1 rounded-15;
  }
  &-redLight-u {
    @apply typo-13-500 px-2 rounded1 bg-redLight text-neutrals9 typo-13-600 leading-none flex items-center justify-center py-1 rounded-15 uppercase;
  }
  &-greenLight {
    @apply typo-13-500 px-2 rounded1 bg-greenLight text-neutrals9 capitalize typo-13-600 leading-none flex items-center justify-center py-1 rounded-15;
  }
  &-primary {
    @apply typo-13-500 px-2 rounded1 bg-primary2 text-neutrals9 capitalize typo-13-600 leading-none flex items-center justify-center py-1 rounded-15;
  }
}
.badge-sm {
  &-common {
    @apply typo-10-600 capitalize bg-neutrals2 dark:bg-neutrals3 rounded1 px-2 text-neutrals9 dark:text-neutrals6;
  }
  &-primary2 {
    @apply typo-10-600 px-2 rounded1 bg-primary2 text-neutrals9 uppercase;
  }
  &-accent {
    @apply typo-10-600 px-2 rounded1 bg-accent1 text-neutrals9 uppercase;
  }
  &-success {
    @apply typo-10-600 px-2 rounded1 bg-green text-neutrals9 uppercase;
  }
  &-error {
    @apply typo-10-600 px-2 rounded1 bg-red bg-opacity-10 text-red uppercase;
  }
  &-error1 {
    @apply typo-10-600 px-2 rounded1 bg-red text-neutrals9 uppercase;
  }
  &-primary2-small {
    @apply typo-12-600 px-2 rounded1 bg-primary2 text-neutrals9 capitalize;
  }
  &-accent-small {
    @apply typo-12-600 px-2 rounded1 bg-accent1 text-neutrals9 capitalize;
  }
  &-success-small {
    @apply typo-12-600 px-2 rounded1 bg-green text-neutrals9 capitalize;
  }
  &-error-small {
    @apply typo-12-600 px-2 rounded1 bg-red bg-opacity-10 text-red capitalize;
  }
  &-error1-small {
    @apply typo-12-600 px-2 rounded1 bg-red text-neutrals9 capitalize;
  }
  &-primary2-big {
    @apply typo-13-600 px-2 rounded1 bg-primary2 text-neutrals9 capitalize;
  }
  &-accent-big {
    @apply typo-13-600 px-2 rounded1 bg-accent1 text-neutrals9 capitalize;
  }
  &-success-big {
    @apply typo-13-600 px-2 rounded1 bg-green text-neutrals9 capitalize;
  }
  &-error-big {
    @apply typo-13-600 px-2 rounded1 bg-red bg-opacity-10 text-red capitalize;
  }
  &-error1-big {
    @apply typo-13-600 px-2 rounded1 bg-red text-neutrals9 capitalize;
  }
}
.badge-light {
  &-green {
    @apply bg-opacity-10 uppercase typo-13-500 px-2 rounded1 bg-accent3 text-accent3;
  }
  &-primary2 {
    @apply bg-opacity-10 uppercase typo-13-500 px-2 rounded1 bg-primary2 text-primary2;
  }
  &-accent2 {
    @apply bg-opacity-10 uppercase typo-13-500 px-2 rounded1 bg-accent2 text-accent2;
  }
  &-accent1 {
    @apply bg-opacity-10 uppercase typo-13-500 px-2 rounded1 bg-accent1 text-accent1;
  }
  &-greenLight {
    @apply bg-opacity-10 bg-greenLight uppercase typo-12-500 px-2 py-1 rounded-8 text-greenLight;
  }
  &-redLight {
    @apply bg-opacity-10 bg-redLight uppercase typo-12-500 px-2 py-1 rounded-8 text-redLight;
  }
  &-yellowLight {
    @apply bg-opacity-10 bg-yellowLight uppercase typo-12-500 px-2 py-1 rounded-8 text-yellowLight;
  }
  &-orangeLight {
    @apply bg-opacity-10 bg-orangeLight uppercase typo-12-500 px-2 py-1 rounded-8 text-orangeLight;
  }
}
