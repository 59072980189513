.login-container {
  background: rgba($primary1, 0.2);

  .login-clip {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  }

  .clip-1 {
    background: $neutrals9;
  }

  .clip-2 {
    background-color: rgba($primary1, 0.7);
  }
}
