.paper1 {
  border: 1px solid #f0f3ff;
  @apply bg-neutrals7 dark:bg-neutrals2 p-4 rounded1;
}

.paper2 {
  border: 1px solid #f0f3ff;
  @apply bg-neutrals7 dark:bg-neutrals2 rounded1;
}

.paper3 {
  border: 1px solid #f0f3ff;
  @apply bg-neutrals7 dark:bg-neutrals3 rounded1;
}

.paper4 {
  border: 1px solid #f0f3ff;
  box-shadow: 0px 4px 30px 0px rgba($neutrals1, 0.06);
  @apply bg-neutrals8 dark:bg-neutrals2 rounded2;
}

.paper5 {
  border: 1px solid #f0f3ff;
  @apply bg-neutrals8 dark:bg-neutrals2 rounded2 p-4;
}

.paper6 {
  border: 1px solid #f0f3ff;
  @apply bg-neutrals8 dark:bg-neutrals2 rounded2 p-2;
}

.paper7 {
  border: 1px solid #f0f3ff;
  @apply bg-neutrals9 dark:bg-neutrals2 rounded2;
}

.paper8 {
  border: 1px solid #f0f3ff;
  @apply bg-neutrals9 dark:bg-neutrals2 p-4 rounded-15;
}

.paper9 {
  border: 1px solid #f0f3ff;
  @apply bg-neutrals9 dark:bg-neutrals2 rounded-15;
}

.paper1-border {
  border: 1px solid #f0f3ff;
  @apply bg-neutrals9 dark:bg-neutrals2 p-4 rounded1 border-common;
}

.qbd-paper {
  &-parent {
    perspective: 150rem;
    transition: all 0.8s ease;
  }
  &-parent.qbd-paper-flip > &-child-front {
    transform: rotateY(180deg);
    opacity: 0;
  }
  &-parent.qbd-paper-flip > &-child-back {
    transform: rotateY(0deg);
    opacity: 1;
  }
  &-child-front,
  &-child-back {
    position: absolute;
    backface-visibility: hidden;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.8s ease;
  }
  &-parent > .qbd-paper-child-front {
    opacity: 1;
    transform: rotateY(0deg);
  }
  &-parent > .qbd-paper-child-back {
    opacity: 0;
    transform: rotateY(-180deg);
  }
}
