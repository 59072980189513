// common styles
body {
  min-width: 375px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-family:
    $primary-font,
    system-ui,
    -apple-system,
    BlinkMacSystemFont;
  background-color: white;
  @apply text-primary3 dark:bg-neutrals1 dark:text-neutrals8;
}
