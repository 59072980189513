$colorArray: (
  "0": $green,
  "1": $blue,
  "2": $red,
  "3": $orange,
);

.chartOfAccounts {
  @each $name, $color in $colorArray {
    &-parent-#{$name} {
      border-left: 3.5px solid #{rgba($color, 0.8)};
    }
    &-child-#{$name} {
      border-left: 3.5px solid #{rgba($color, 0.4)};
    }
  }
}

.mapAISAccounts {
  &-sub-icon {
    opacity: 0;
    transition: opacity 0.2s;
  }
  &-ais-icon {
    opacity: 0;
    transition: opacity 0.2s;
  }
  &-sub:hover {
    .mapAISAccounts-sub-icon {
      opacity: 0.8;
    }
  }
  &-ais:hover {
    .mapAISAccounts-ais-icon {
      opacity: 0.8;
    }
  }

  @each $name, $color in $colorArray {
    &-parent-#{$name} {
      border-left: 3.5px solid #{rgba($color, 0.9)};
    }
    &-sub-#{$name} {
      border-left: 3.5px solid #{rgba($color, 0.5)};
    }
    &-ais-#{$name} {
      border-left: 3.5px solid #{rgba($color, 0.2)};
    }
  }
}

.qbo-progress-bar {
  &-progress {
    background: linear-gradient(
      90deg,
      rgba($accent3, 0.5),
      rgba($accent3, 0.8) 20%,
      rgba($accent3, 0.95) 34%,
      rgba($accent3, 0.5) 68%,
      rgba($accent3, 0.8) 70%,
      rgba($accent3, 0.95)
    );
    background-size: 300% 100%;
    animation: AnimationName 2s linear infinite;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}
