$height: 44;

.custom-select {
  .css-1s2u09g-control {
    width: 100%;
    height: $height + px;
    padding: 0 8px;
    border-radius: 6px;
    background: none;
    transition: border-color 0.2s;
    border: 1px solid;
    color: $primary3;
    border-color: $neutrals6;

    &:hover {
      height: $height + px;
      padding: 0 8px;
      border-radius: 6px;
      background: none;
      transition: border-color 0.2s;
      border: 1px solid;
      color: $primary3;
      border-color: $neutrals6;
    }
  }

  .css-1okebmr-indicatorSeparator {
    background: transparent;
  }
}
