.react-menu-tooltip {
  @apply bg-primary3 text-neutrals9;
  .szh-menu {
    max-width: 320px;
    background-color: inherit;
    color: inherit;
    .szh-menu__arrow {
      background-color: inherit;
      color: inherit;
    }
  }
}

.react-menu-tooltip-sm {
  @apply bg-primary3 text-neutrals9;
  .szh-menu {
    min-width: fit-content;
    max-width: 320px;
    background-color: inherit;
    color: inherit;
    padding: 0.2rem 0;
    .szh-menu__arrow {
      background-color: inherit;
      color: inherit;
    }
  }
}

.react-menu-options {
  @apply bg-neutrals7 dark:bg-neutrals3 text-primary3 dark:text-neutrals7;
  // background-color: $neutrals7;
  // color: $primary3;
  .szh-menu {
    background-color: inherit;
    color: inherit;
    padding: 0.5rem;
    .szh-menu__arrow {
      background-color: inherit;
      color: inherit;
    }
    .szh-menu__item {
      padding: 0.375rem 1rem;
      border-radius: 6px;
      &--hover {
        @apply bg-neutrals6 dark:bg-neutrals2;
      }
    }
  }
}

.react-menu-select {
  @apply bg-neutrals7 dark:bg-neutrals1 common-shadow-light;
  .szh-menu {
    min-width: 300px;
    background-color: inherit;
    color: inherit;
    .szh-menu__arrow {
      background-color: inherit;
      color: inherit;
    }
    .szh-menu__item {
      background-color: inherit;
      color: inherit;
      align-items: flex-end;
      &:hover {
        background-color: inherit;
        color: inherit;
      }
    }
  }
}

.react-menu-toolbar {
  @apply bg-neutrals9 dark:bg-neutrals1;
  .szh-menu {
    min-width: 150px;
    background-color: inherit;
    color: inherit;
    .szh-menu__arrow {
      background-color: inherit;
      color: inherit;
    }
    .szh-menu__item {
      background-color: inherit;
      color: inherit;
      align-items: flex-end;
      &:hover {
        background-color: inherit;
        color: inherit;
      }
    }
  }
}

.react-menu-tooltip-custom {
  @apply text-accent2;
  .szh-menu {
    max-width: 300px;
    padding: 10px;
    background-color: #ffcdcd;
    color: inherit;
    .szh-menu__arrow {
      background-color: #ffcdcd;
      color: inherit;
    }
  }
}

.react-menu-tooltip-custom-warning {
  @apply text-yellow-500;
  .szh-menu {
    max-width: 300px;
    padding: 10px;
    background-color: (#fffdcd);
    color: inherit;
    .szh-menu__arrow {
      background-color: #fffdcd;
      color: inherit;
    }
  }
}
