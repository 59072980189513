@import "../common/colors";
.z-1 {
  z-index: 1;
}

$sizeArray: (
  "6": "6px",
  "10": "10px",
  "11": "11px",
  "12": "12px",
  "14": "14px",
  "16": "16px",
  "18": "18px",
  "20": "20px",
  "22": "22px",
  "24": "24px",
  "26": "26px",
  "30": "30px",
  "34": "34px",
  "38": "38px",
  "42": "42px",
  "46": "46px",
  "50": "50px",
  "54": "54px",
  "58": "58px",
);

@each $name, $size in $sizeArray {
  .font-#{$name} {
    font-size: #{$size};
  }
}

.rotate-icon {
  transform: rotate(135deg);
  transition: all 0.3s;
  &-reverse {
    transform: rotate(-90deg);
    transition: all 0.3s;
  }
}

.rotate-arrow {
  transform: rotate(360deg);
  transition: all 0.3s;
  &-reverse {
    transform: rotate(-180deg);
    transition: all 0.3s;
  }
}

.report-table {
  table,
  th,
  td {
    border-collapse: separate;
    padding: 3px;
  }

  .ytd {
    td:last-child {
      padding: 0 0 0 10px;
      div {
        padding: 3px;
        width: 100%;
        min-width: 70px;
      }
    }
  }

  .ytd-total {
    td:last-child {
      padding: 0 0 0 10px;
      background: transparent;
      border: none;
      div {
        background: $neutrals7;
        padding: 3px;
        min-width: 70px;
      }
    }
  }

  .statement-ytd {
    td:nth-child(6) {
      padding: 0 0 0 10px;
      div {
        padding: 3px;
        width: 100%;
        min-width: 70px;
      }
    }
  }

  .statement-ytd-total {
    td:nth-child(6) {
      padding: 0 0 0 10px;
      background: transparent;
      border: none;
      div {
        padding: 3px;
        min-width: 70px;
      }
    }
  }

  .first-cell {
    width: fit-content;
    white-space: nowrap;
  }

  .border {
    border: 1px solid $neutrals5;
  }

  .border-t-b {
    border-top: 1px solid $neutrals5;
    border-bottom: 1px solid $neutrals5;
  }

  .header {
    td {
      text-align: center;
    }
  }

  .parent {
    td {
      height: 40px;
      vertical-align: bottom;
    }
  }

  .sub-account {
    td {
      padding-left: 15px;
    }
  }
  .ais-account {
    td {
      padding-left: 30px;
    }
  }
}

.showIconOnHover {
  .showHide {
    display: none;
  }

  &:hover {
    .showHide {
      display: block;
    }
  }
}

.dfm-table {
  table,
  th,
  td {
    border-collapse: collapse;
    padding: 3px 5px;
    min-width: 45px;
  }

  .yoy {
    td:last-child {
      padding: 0 0 0 10px;
      div {
        padding: 2px;
        width: 100%;
        min-width: 40px;
      }
    }
    .first-cell-cfs {
      padding-right: 20px;
    }
  }

  .title {
    min-width: 50px;
  }
  .title-right-space {
    padding-right: 20px;
  }
}

.dfm-table-big {
  table,
  th,
  td {
    border-collapse: collapse;
    padding: 5px 8px;
    min-width: 65px;
  }

  .yoy {
    td:last-child {
      padding: 0 0 0 10px;
      div {
        padding: 2px;
        width: 100%;
        min-width: 50px;
      }
    }
  }

  .title {
    min-width: 50px;
  }
}
.bs-table {
  table,
  th,
  td {
    border-collapse: collapse;
    padding: 3px 5px;
    min-width: 50px;
  }
  .title {
    min-width: 50px;
  }
}

.bs-table-big {
  table,
  th,
  td {
    border-collapse: collapse;
    padding: 5px 8px;
    min-width: 65px;
  }
  .title {
    min-width: 50px;
  }
}

.report-13-month-table {
  table,
  th,
  td {
    border-collapse: collapse;
    padding: 2px;
  }

  .first-cell {
    width: fit-content;
    white-space: nowrap;
  }

  .border {
    border: 1px solid $neutrals5;
  }

  .border-t-b {
    border-top: 1px solid $neutrals5;
    border-bottom: 1px solid $neutrals5;
  }

  .header {
    td {
      text-align: center;
    }
  }

  .sub-account {
    td {
      padding-left: 15px;
    }
  }

  .ais-account {
    td {
      padding-left: 30px;
    }
  }

  .fixed-cell {
    width: 60px !important;
  }
}

.rotate-y {
  transform: rotateY(180deg);
}

.report-table-without-note {
  table,
  th,
  td {
    border-collapse: separate;
    padding: 3px;
  }

  .ytd {
    td:last-child {
      padding: 0 0 0 10px;
      div {
        padding: 3px;
        width: 100%;
        min-width: 70px;
      }
    }
  }

  .ytd-total {
    td:last-child {
      padding: 0 0 0 10px;
      background: transparent;
      border: none;
      div {
        background: $neutrals7;
        padding: 3px;
        min-width: 70px;
      }
    }
  }

  .statement-ytd {
    td:nth-child(5) {
      padding: 0 0 0 10px;
      div {
        padding: 3px;
        width: 100%;
        min-width: 70px;
      }
    }
  }

  .statement-ytd-total {
    td:nth-child(5) {
      padding: 0 0 0 10px;
      background: transparent;
      border: none;
      div {
        padding: 3px;
        min-width: 70px;
      }
    }
  }

  .first-cell {
    width: fit-content;
    white-space: nowrap;
  }

  .border {
    border: 1px solid $neutrals5;
  }

  .border-t-b {
    border-top: 1px solid $neutrals5;
    border-bottom: 1px solid $neutrals5;
  }

  .header {
    td {
      text-align: center;
    }
  }

  .parent {
    td {
      height: 40px;
      vertical-align: bottom;
    }
  }

  .sub-account {
    td {
      padding-left: 15px;
    }
  }
  .ais-account {
    td {
      padding-left: 30px;
    }
  }
}

.blank-report-table {
  table,
  th,
  td {
    border-collapse: collapse;
    padding: 3px;
    min-width: 70px;
  }

  .ytd {
    td:last-child {
      padding: 0 0 0 10px;
      div {
        padding: 3px;
        width: 100%;
        min-width: 70px;
      }
    }
  }

  .ytd-total {
    td:last-child {
      padding: 0 0 0 10px;
      background: transparent;
      border: none;
      div {
        padding: 3px;
        min-width: 70px;
      }
    }
  }

  .statement-ytd {
    td:nth-child(6) {
      padding: 0 0 0 10px;
      div {
        padding: 3px;
        width: 100%;
        min-width: 70px;
      }
    }
  }

  .statement-ytd-total {
    td:nth-child(6) {
      padding: 0 0 0 10px;
      background: transparent;
      border: none;
      div {
        padding: 3px;
        min-width: 70px;
      }
    }
  }

  .first-cell {
    width: fit-content;
    white-space: nowrap;
  }

  .border {
    border: 1px solid $neutrals5;
  }

  .border-t-b {
    border-top: 1px solid $neutrals5;
    border-bottom: 1px solid $neutrals5;
  }

  .border-b {
    border-bottom: 1px solid $neutrals5;
  }

  .header {
    td {
      text-align: center;
    }
  }

  .parent {
    td {
      height: 40px;
      vertical-align: bottom;
    }
  }

  .sub-account {
    td {
      padding-left: 15px;
    }
  }
  .ais-account {
    td {
      padding-left: 30px;
    }
  }

  .fixed-cell {
    width: 60px !important;
  }
}

.blank-report-13-month-table {
  table,
  th,
  td {
    border-collapse: collapse;
    padding: 2px;
    min-width: 50px;
  }

  .ytd {
    td:last-child {
      padding: 0 0 0 10px;
      div {
        padding: 2px;
        width: 100%;
        min-width: 50px;
      }
    }
  }

  .ytd-total {
    td:last-child {
      padding: 0 0 0 10px;
      background: transparent;
      border: none;
      div {
        padding: 3px;
        min-width: 50px;
      }
    }
  }

  .statement-ytd {
    td:nth-child(6) {
      padding: 0 0 0 10px;
      div {
        padding: 2px;
        width: 100%;
        min-width: 50px;
      }
    }
  }

  .statement-ytd-total {
    td:nth-child(6) {
      padding: 0 0 0 10px;
      background: transparent;
      border: none;
      div {
        padding: 2px;
        min-width: 50px;
      }
    }
  }

  .first-cell {
    width: fit-content;
    white-space: nowrap;
  }

  .border {
    border: 1px solid $neutrals5;
  }

  .border-t-b {
    border-top: 1px solid $neutrals5;
    border-bottom: 1px solid $neutrals5;
  }

  .header {
    td {
      text-align: center;
    }
  }

  .sub-account {
    td {
      padding-left: 15px;
    }
  }

  .ais-account {
    td {
      padding-left: 30px;
    }
  }

  .space-td {
    min-width: 20px !important;
  }
}

.blank-report-13-month-table-big {
  table {
    width: fit-content;
  }
  table,
  th,
  td {
    border-collapse: collapse;
    padding: 2px;
    min-width: 70px;
  }

  .ytd {
    td:last-child {
      padding: 0 0 0 10px;
      div {
        padding: 2px;
        width: 100%;
        min-width: 70px;
      }
    }
  }

  .ytd-total {
    td:last-child {
      padding: 0 0 0 10px;
      background: transparent;
      border: none;
      div {
        padding: 2px;
        min-width: 70px;
      }
    }
  }

  .statement-ytd {
    td:nth-child(6) {
      padding: 0 0 0 10px;
      div {
        padding: 2px;
        width: 100%;
        min-width: 70px;
      }
    }
  }

  .statement-ytd-total {
    td:nth-child(6) {
      padding: 0 0 0 10px;
      background: transparent;
      border: none;
      div {
        padding: 2px;
        min-width: 70px;
      }
    }
  }

  .first-cell {
    width: fit-content;
    white-space: nowrap;
  }

  .border {
    border: 1px solid $neutrals5;
  }

  .border-t-b {
    border-top: 1px solid $neutrals5;
    border-bottom: 1px solid $neutrals5;
  }

  .header {
    td {
      text-align: center;
    }
  }

  .sub-account {
    td {
      padding-left: 15px;
    }
  }

  .ais-account {
    td {
      padding-left: 30px;
    }
  }

  .space-td {
    min-width: 20px !important;
  }
}

.chart-table {
  table,
  th,
  td {
    border-collapse: collapse;
    border: 1px solid $neutrals5;
    padding: 2px;
    min-width: 35px;
  }
}

.chart-table-small {
  table,
  th,
  td {
    border-collapse: collapse;
    border: 1px solid $neutrals5;
    padding: 2px;
    min-width: 45px;
    @media (max-width: 1536px) {
      min-width: 35px;
    }
  }
}

.sales-by-customer-table-small {
  table,
  th,
  td {
    border-collapse: collapse;
    border: 1px solid $neutrals5;
    padding: 2px;
    min-width: 60px;
    @media (max-width: 1536px) {
      min-width: 80px;
    }
  }
}

.custom-table-small {
  table,
  th,
  td {
    border-collapse: collapse;
    border: 1px solid $neutrals5;
    padding: 2px;
    min-width: 50px;
    height: 20px;
    min-height: 20px;
  }
}

.edit-custom-table-small {
  table,
  th,
  td {
    border-collapse: collapse;
    border: 1px solid $neutrals5;
    padding: 2px;
    min-width: 50px;
    height: 20px;
    min-height: 20px;
  }
}

.blank-notes-table {
  table,
  th,
  td {
    border-collapse: collapse;
    padding: 3px;
    min-width: 50px !important;
  }

  .ytd {
    td:last-child {
      padding: 0 0 0 10px;
      div {
        padding: 3px;
        width: 100%;
        min-width: 70px;
      }
    }
  }

  .ytd-total {
    td:last-child {
      padding: 0 0 0 10px;
      background: transparent;
      border: none;
      div {
        padding: 3px;
        min-width: 70px;
      }
    }
  }

  .statement-ytd {
    td:nth-child(6) {
      padding: 0 0 0 10px;
      div {
        padding: 3px;
        width: 100%;
        min-width: 70px;
      }
    }
  }

  .statement-ytd-total {
    td:nth-child(6) {
      padding: 0 0 0 10px;
      background: transparent;
      border: none;
      div {
        padding: 3px;
        min-width: 70px;
      }
    }
  }

  .first-cell {
    width: fit-content;
    white-space: nowrap;
  }

  .border {
    border: 1px solid $neutrals5;
  }

  .border-t-b {
    border-top: 1px solid $neutrals5;
    border-bottom: 1px solid $neutrals5;
  }

  .border-b {
    border-bottom: 1px solid $neutrals5;
  }

  .header {
    td {
      text-align: center;
    }
  }

  .parent {
    td {
      height: 40px;
      vertical-align: bottom;
    }
  }

  .sub-account {
    td {
      padding-left: 15px;
    }
  }
  .ais-account {
    td {
      padding-left: 30px;
    }
  }
}
