// typo
.heading {
  &-22 {
    font-size: 22px;
    font-weight: 700;
  }
  &-20 {
    font-size: 20px;
    font-weight: 700;
    &-600 {
      font-size: 20px;
      font-weight: 600;
    }
  }
  &-18 {
    font-size: 18px;
    font-weight: 700;
    &-600 {
      font-size: 18px;
      font-weight: 600;
    }
    &-800 {
      font-size: 18px;
      font-weight: 800;
    }
  }
  &-16 {
    font-size: 16px;
    font-weight: 600;
    &-700 {
      font-size: 16px;
      font-weight: 700;
    }
    &-800 {
      font-size: 16px;
      font-weight: 800;
    }
  }
  &-14 {
    font-size: 14px;
    font-weight: 600;
    &-700 {
      font-size: 14px;
      font-weight: 700;
    }
  }
  &-13 {
    font-size: 13px;
    font-weight: 600;
  }
  &-12 {
    font-size: 12px;
    font-weight: 600;
  }
  &-11 {
    font-size: 11px;
    font-weight: 600;
  }
  &-3-800 {
    font-size: 3rem;
    font-weight: 800;
  }
}

.typo {
  &-7 {
    font-size: 7px;
    font-weight: 400;
    &-600 {
      font-size: 7px;
      font-weight: 600;
    }
    &-800 {
      font-size: 7px;
      font-weight: 800;
    }
  }
  &-8 {
    font-size: 8px;
    font-weight: 400;
    &-600 {
      font-size: 8px;
      font-weight: 600;
    }
    &-800 {
      font-size: 8px;
      font-weight: 800;
    }
  }
  &-9 {
    font-size: 9px;
    font-weight: 400;
    &-600 {
      font-size: 9px;
      font-weight: 600;
    }
    &-800 {
      font-size: 9px;
      font-weight: 800;
    }
  }
  &-10 {
    font-size: 10px;
    font-weight: 400;
    &-600 {
      font-size: 10px;
      font-weight: 600;
    }
    &-700 {
      font-size: 10px;
      font-weight: 700;
    }
    &-800 {
      font-size: 10px;
      font-weight: 800;
    }
  }
  &-11 {
    font-size: 11px;
    font-weight: 400;
    &-600 {
      font-size: 11px;
      font-weight: 600;
    }
    &-700 {
      font-size: 11px;
      font-weight: 700;
    }
    &-800 {
      font-size: 11px;
      font-weight: 800;
    }
  }
  &-12 {
    font-size: 12px;
    font-weight: 400;
    &-300 {
      font-size: 12px;
      font-weight: 300;
    }
    &-500 {
      font-size: 12px;
      font-weight: 500;
    }
    &-600 {
      font-size: 12px;
      font-weight: 600;
    }
    &-700 {
      font-size: 12px;
      font-weight: 700;
    }
    &-800 {
      font-size: 12px;
      font-weight: 800;
    }
  }
  &-13 {
    font-size: 13px;
    font-weight: 400;
    &-300 {
      font-size: 13px;
      font-weight: 300;
    }
    &-500 {
      font-size: 13px;
      font-weight: 500;
    }
    &-600 {
      font-size: 13px;
      font-weight: 600;
    }
    &-800 {
      font-size: 13px;
      font-weight: 800;
    }
  }
  &-14 {
    font-size: 14px;
    font-weight: 400;
    &-500 {
      font-size: 14px;
      font-weight: 500;
    }
    &-600 {
      font-size: 14px;
      font-weight: 600;
    }
    &-700 {
      font-size: 14px;
      font-weight: 700;
    }
  }
  &-15 {
    font-size: 15px;
    font-weight: 400;
    &-500 {
      font-size: 15px;
      font-weight: 500;
    }
    &-600 {
      font-size: 15px;
      font-weight: 600;
    }
    &-800 {
      font-size: 15px;
      font-weight: 800;
    }
  }
  &-16 {
    font-size: 16px;
    font-weight: 400;
    &-500 {
      font-size: 16px;
      font-weight: 500;
    }
    &-600 {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.btn-typo {
  &-12 {
    font-size: 12px;
    font-weight: 500;
  }
  &-13 {
    font-size: 13px;
    font-weight: 600;
  }
  &-14 {
    font-size: 14px;
    font-weight: 600;
  }
  &-16 {
    font-size: 16px;
    font-weight: 500;
  }
}

.input-label {
  &-13 {
    font-size: 13px;
    font-weight: 600;
  }
  &-14 {
    font-size: 14px;
    font-weight: 500;
  }
}
// labels
.table-label {
  &-14 {
    font-size: 14px;
    font-weight: 500;
  }
  &-13 {
    font-size: 13px;
    font-weight: 500;
  }
  &-12 {
    font-size: 12px;
    font-weight: 500;
  }
}

.input-error-label {
  &-13 {
    font-size: 13px;
    font-weight: 400;
  }
  &-12 {
    font-size: 12px;
    font-weight: 400;
  }
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

.tooltip-typo {
  &-15 {
    font-size: 15px;
    font-weight: 400;
  }
  &-13 {
    font-size: 13px;
    font-weight: 400;
  }
}

.link-effect {
  @apply hover:text-primary2 dark:hover:text-primary2 hover:underline cursor-pointer;
}

@media print {
  @page {
    size: A4 portrait;
  }
  @page rotated {
    page-orientation: rotate-left;
  }
  .print-container {
    @apply shadow-none;
    height: 100vh;
    width: 100vw;
    page-break-after: always;

    &.landscape {
      page: rotated;
      transform-origin: top left;
      transform: translateX(100vw) rotate(90deg);
      width: 100vh;
      height: 100vw;
    }
  }
}

@media screen {
  .print-container {
    height: 297mm;
    width: 210mm;

    &.landscape {
      min-height: 210mm;
      height: 210mm;
      width: 297mm;
    }
  }
}
