@keyframes increaseHeight {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

.increaseHeightTransition {
  transition: all 0.9s;
  animation: increaseHeight 0.9s ease-in-out backwards;
}

@keyframes increaseWidth {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.increaseWidthTransition {
  transition: all 0.9s;
  animation: increaseWidth 0.9s ease-in-out backwards;
}

@keyframes moveDownFrame {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes moveUpFrame {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  80% {
    transform: translateY(-0.5rem);
  }
  100% {
    transform: translateY(0);
  }
}

.moveToDown {
  transition: all 0.4s;
  animation: moveDownFrame 0.4s ease-in-out backwards;
}

.moveToUp {
  transition: all 0.4s;
  animation: moveUpFrame 0.4s ease-out;
}

@keyframes moveDownFrameUnMount {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  20% {
    transform: translateY(0.5rem);
  }
  100% {
    opacity: 0;
    transform: translateY(-2rem);
  }
}

@keyframes moveDownFrame {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  80% {
    opacity: 1;
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes antiRotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes antiRotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes scaleSmallToBig {
  0% {
    transform: scale(0.4);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes moveLeftFrame {
  0% {
    opacity: 0;
    transform: translateX(1rem);
  }
  80% {
    opacity: 0.8;
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveRightFrame {
  0% {
    opacity: 0;
    transform: translateX(-1rem);
  }
  80% {
    transform: translateX(0.5rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.moveToDownUnMount {
  transition: all 0.4s;
  animation: moveDownFrameUnMount 0.4s ease-in-out backwards;
}

.moveToDown {
  transition: all 0.4s;
  animation: moveDownFrame 0.4s ease-in-out backwards;
}

.rotateClock {
  -webkit-animation: rotating 1.5s ease-in-out infinite;
  -moz-animation: rotating 1.5s ease-in-out infinite;
  -ms-animation: rotating 1.5s ease-in-out infinite;
  -o-animation: rotating 1.5s ease-in-out infinite;
  animation: rotating 1.5s ease-in-out infinite;
}
.rotateAntiClock {
  -webkit-animation: antiRotating 1.5s ease-in-out infinite;
  -moz-animation: antiRotating 1.5s ease-in-out infinite;
  -ms-animation: antiRotating 1.5s ease-in-out infinite;
  -o-animation: antiRotating 1.5s ease-in-out infinite;
  animation: antiRotating 1.5s ease-in-out infinite;
}

.scaleSmallToBigAnimation {
  animation: scaleSmallToBig 0.3s ease-in-out;
}

.moveLeft {
  transition: all 0.4s;
  animation: moveLeftFrame 0.4s ease-in-out;
}

.moveRight {
  transition: all 0.4s;
  animation: moveRightFrame 0.4s ease-in-out;
}

.rotateInfinite {
  animation: spin 2s linear infinite;
}

.rotateX180 {
  transform: rotateX(180deg);
}
