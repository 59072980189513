.bottom-high {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.shadow-all-low {
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 8px 1px;
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: rgb(0 0 0 / 10%) 0px 8px 10px 4px;
  }
  &-hover {
    transition: box-shadow 0.3s;
    &:hover {
      box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px;
    }
  }
}

.white-shadow-all-low {
  box-shadow: rgb(255 255 255 / 10%) 0px 2px 8px 1px;
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: rgb(255 255 255 / 10%) 0px 8px 10px 4px;
  }
  &-hover {
    transition: box-shadow 0.3s;
    &:hover {
      box-shadow: rgb(255 255 255 / 10%) 0px 2px 10px;
    }
  }
}

.clientItem {
  box-shadow: 0px 4px 40px 0px rgba($neutrals1, 0.06);

  &-box {
    box-shadow: 0px 6px 16px 0px rgba($neutrals1, 0.05);
  }
}

.common-shadow {
  box-shadow: 0px 8px 16px rgba(28, 28, 28, 0.15);
  &-hover:hover {
    transition: box-shadow 350ms ease-in-out;
    box-shadow: 0px 8px 16px rgba(28, 28, 28, 0.15);
  }
  &-accent {
    box-shadow: 0px 0px 4px rgba($accent1, 0.85);
  }
  &-hover-accent:hover {
    transition: box-shadow 350ms ease-in-out;
    box-shadow: 0px 0px 4px rgba($accent1, 0.85);
  }
  &-hover-accent2:hover {
    transition: box-shadow 350ms ease-in-out;
    box-shadow: 0px 0px 4px rgba($accent2, 0.85);
  }
  &-hover-primary2:hover {
    transition: box-shadow 350ms ease-in-out;
    box-shadow: 0px 0px 4px rgba($primary2, 0.85);
  }
  &-hover-green {
    transition: box-shadow 350ms ease-in-out;
    &:hover {
      box-shadow: 0px 0px 4px rgba($green, 0.85);
    }
  }
  &-hover-light-green {
    transition: box-shadow 350ms ease-in-out;
    &:hover {
      box-shadow: 0px 0px 4px rgba($greenLight, 0.85);
    }
  }
  &-hover-light-red {
    transition: box-shadow 350ms ease-in-out;
    &:hover {
      box-shadow: 0px 0px 4px rgba($redLight, 0.85);
    }
  }
  &-hover-gray {
    transition: box-shadow 350ms ease-in-out;
    &:hover {
      box-shadow: 0px 0px 4px rgba($neutrals4, 0.85);
    }
  }
  &-hover1:hover {
    transition: box-shadow 250ms ease-in-out;
    box-shadow: 0px 4px 8px rgba(28, 28, 28, 0.15);
  }
  &-light {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
  }
  &-hover-blue:hover {
    transition: box-shadow 350ms ease-in-out;
    box-shadow: 0px 0px 4px rgba($blue, 0.85);
  }
  &-blue {
    box-shadow: 0px 0px 4px rgba($blue, 0.85);
  }
}

.bottom-shadow {
  box-shadow: 0px 10px 7px -15px #111;
}

.top-shadow {
  box-shadow: rgb(149 157 165 / 20%) 0px 0px 17px;
}

.report-card-shadow {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
}

.image-shadow {
  background: rgba(255, 255, 255, 0.01);
  box-shadow: inset 6px 6px 34px #5879ff;
  backdrop-filter: blur(10px);
  border-radius: 10px;
}
