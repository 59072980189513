.stickySidebar {
  // $pad: 1rem;
  position: sticky;
  align-self: flex-start;
  top: 0;
  min-height: 100vh;
}
.sidebarCategory {
  transition: all 0.6s;
  .showOptions {
    max-height: 1000px;
    opacity: 1;
    pointer-events: auto;
  }
  .hideOptions {
    transform: translateY(-1rem);
    max-height: 0;
    opacity: 0;
    pointer-events: none;
  }
}

.responsive-sidebar {
  min-height: 100vh;
  z-index: 100;
}
