.scroll-common {
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: $neutrals6;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    @apply bg-brand-secondary;
  }
}

.scroll-common-neutrals6 {
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 4px;
    width: 5px;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: $neutrals6;
  }
}
.scroll-common-primary4 {
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 4px;
    width: 5px;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    @apply bg-brand-secondary;
  }
}

.scroll-x-common {
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: $neutrals6;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: $primary2;
  }
}

.sidebar-scroll {
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(252, 252, 252, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #d9d9d9;
  }
}

.scroll-clean {
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 4px;
    width: 5px;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #EDF2F7;
    min-height: 25px;
  }
}